/*!
 Theme Name:   Greg Copeland Theme
 Description:  Theme for the website of Greg Copeland based on twentyseventeen from WP
 Author:       Christopher Wardle
 Template:     twentyseventeen
 Version:      1.0.0
 License:      GNU General Public License v2 or later
 License URI:  http://www.gnu.org/licenses/gpl-2.0.html
 Text Domain:  copeland
*/

@charset "UTF-8";

// Third Party Components
// Materialize
@import "../../node_modules/materialize-css/sass/materialize.scss";

// Util
@import "util/z-index";
@import "util/functions";
@import "util/mixins";
@import "util/wp-admin";
@import "util/animations";

// Custom Variables
@import "variables/variables";

// Reset
@import "../../node_modules/materialize-css/sass/components/normalize";

// ../../node_modules/materialize-css/sass/components
@import "../../node_modules/materialize-css/sass/components/global";
@import "../../node_modules/materialize-css/sass/components/badges";
@import "../../node_modules/materialize-css/sass/components/icons-material-design";
@import "../../node_modules/materialize-css/sass/components/grid";
@import "../../node_modules/materialize-css/sass/components/navbar";
@import "../../node_modules/materialize-css/sass/components/roboto";
@import "../../node_modules/materialize-css/sass/components/typography";
@import "../../node_modules/materialize-css/sass/components/transitions";
@import "../../node_modules/materialize-css/sass/components/cards";
// @import "../../node_modules/materialize-css/sass/components/toast";
// @import "../../node_modules/materialize-css/sass/components/tabs";
@import "../../node_modules/materialize-css/sass/components/tooltip";
@import "../../node_modules/materialize-css/sass/components/buttons";
@import "../../node_modules/materialize-css/sass/components/dropdown";
// @import "../../node_modules/materialize-css/sass/components/waves";
// @import "../../node_modules/materialize-css/sass/components/modal";
// @import "../../node_modules/materialize-css/sass/components/collapsible";
// @import "../../node_modules/materialize-css/sass/components/chips";
// @import "../../node_modules/materialize-css/sass/components/materialbox";
// @import "../../node_modules/materialize-css/sass/components/forms/forms";
// @import "../../node_modules/materialize-css/sass/components/table_of_contents";
@import "../../node_modules/materialize-css/sass/components/sideNav";
@import "../../node_modules/materialize-css/sass/components/preloader";
// @import "../../node_modules/materialize-css/sass/components/slider";
// @import "../../node_modules/materialize-css/sass/components/carousel";
// @import "../../node_modules/materialize-css/sass/components/tapTarget";
// @import "../../node_modules/materialize-css/sass/components/pulse";
// @import "../../node_modules/materialize-css/sass/components/date_picker/default";
// @import "../../node_modules/materialize-css/sass/components/date_picker/default.date";
// @import "../../node_modules/materialize-css/sass/components/date_picker/default.time";

// Typography
@import "typography/base";
@import "typography/headings";

// Site
@import "site/primary";
@import "site/parallax";
@import "site/front-page";
@import "site/category";
@import "site/page";
@import "site/posts";
@import "site/aside";

// Header
@import "site/header/header";
@import "site/header/full-width-header";

// Footer
@import "site/footer/footer";
@import "site/footer/footer-social";
@import "site/footer/sticky-footer";

// Navigation
@import "navigation/navigation";
@import "navigation/mobile-navigation";
@import "navigation/link";
@import "navigation/button-link";

// Modules
@import "modules/hero";
@import "modules/card";
@import "modules/contact-form";
@import "modules/order-form";
@import "modules/gallery";
