.side-nav {
    max-width: 100%;
    background: $primary-color;
    
    .menu-item {
        border-bottom: 1px solid $shadow;
        a {
            color: white;

            &:hover,
            &:focus {
                background: white;
                color: $primary-color;
            }
        }
    }
}