/* Custom Stylesheet */
/**
 * Use this file to override Materialize files so you can update
 * the core Materialize files in the future
 *
 * Made By MaterializeCSS.com
 */
 
 p {
   line-height: 2rem;
 }
 
 .button-collapse {
   color: #26a69a;
 }
 
 .parallax-container {
   min-height: 200px;
   line-height: 0;
   height: auto;
   color: rgba(255,255,255,.9);
   box-shadow: inset 0 13px 20px $shadow, inset 0 -13px 20px $shadow;

   @media #{$medium-and-up} {
       min-height: 300px;
   }

   @media #{$large-and-up} {
       min-height: 500px;
   }
 }
   .parallax-container .section {
     width: 100%;
   }
 
 @media only screen and (max-width : 992px) {
   .parallax-container .section {
     position: absolute;
     top: 40%;
   }
   #index-banner .section {
     top: 10%;
   }
 }
 
 @media only screen and (max-width : 600px) {
   #index-banner .section {
     top: 0;
   }
 }
 
 .icon-block {
   padding: 0 15px;
 }
 .icon-block .material-icons {
   font-size: inherit;
 }
 
 footer.page-footer {
   margin: 0;
 }