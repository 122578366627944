div {
    .wpcf7-response-output {
        position: relative;
        margin-left: 0;
        margin-right: 0;
        padding: 2em;
        padding-left: 4em;

        &::before {
            position: absolute;
            left: 2%;
            top: 50%;
            font-family: 'Material Icons';
            font-size: 2em;
            transform: translateY(-50%);
        }
    }

    .wpcf7-validation-errors,
    .wpcf7-mail-sent-ng {
        border-color: color("red", "base");

        &::before {
            content: '\e000';
            color: color("red", "base");
        }
    }

    .wpcf7-mail-sent-ok {
        border-color: color('green', 'base');

        &::before {
            content: '\e86c';
            color: color('green', 'base');
        }
    }

    .wpcf7-submit {
        background-color: ghostwhite;
        color: rgb(36,36,36);
    }
}