.social-menu {
    list-style-type: none;
    margin: 0 0 1em;
    text-align: center;
    
    li {
      display: inline-block;
      margin: 0 1.5em;
    }
    
    a {
      padding: .5em;
      border-bottom: none;
      font-size: 3em;
      
      & > .icon {
        color: white;
        transition: color .3s;
      }

      &:hover,
      &:focus {
        transition: color .3s;
        
        & > .icon-twitter {
          color: $twitter;
        }
        
        & > .icon-facebook {
          color: $facebook;
        }
        
        & > .icon-youtube {
          color: $youtube;
        }
      }
    }
  }
  