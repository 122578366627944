.anchor {
    display: block;
    position: relative;
    top: -60px;
    visibility: hidden;
}

.highlight {
    font-size: 1.4rem;
    background: invert($primary-color);
    color: $secondary-color;
}
