/*  -------
 *  1. Global
 *  ------*/
//font size in pixel but without the unit as this is a utility variable for functions
$browser-context: 16;
$roboto-font-path: "assets/fonts/roboto/";
$small-font-size: 12px;
$border-width: 15px;

/*  -------
 *  2. Colours
 *  ------*/
$primary-color: rgb(133, 119, 80);
$secondary-color: white;
$shadow: rgba(0, 0, 0, 0.808);
$facebook: rgb(72, 103, 170);
$twitter: rgb(29, 161, 242);
$youtube: rgb(225, 43, 40);

/*  -------
 *  3. Hero
 *  ------*/
$hero-height: 100%;
$hero-height-modifier: 0.5;
$hero-overlay-color: rgba(28, 30, 31, 0.425);
$hero-slides-max: 10;
$hero-slide-duration: 8s;
$hero-transition-duration: 1s;
$hero-scale-factor: 20%;

/*  -------
 *  4. Cards
 *  ------*/
$card-bg-color: $primary-color;
$card-link-color: white;