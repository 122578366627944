#sidenav-overlay {
    z-index: 0;
}

.navbar-fixed nav {
    @media #{$medium-and-up} {
        width: calc(100% - (#{$border-width} * 2));
    }
}

nav .button-collapse {
    margin: 0 18px 0 0;
}

.comment-navigation,
.posts-navigation,
.post-navigation,
.paging-navigation {
    height: auto;
    line-height: normal;
    padding: 2em 0;
    text-align: left;
    overflow: hidden;
    background-color: transparent;
    box-shadow: none;

    a {
        display: block;
        width: 100%;
        padding: 1em;
        border: 1px solid lightgrey;
        transition: background .3s ease-out, border-color .3s ease-out;

        .nav-title {
            box-shadow: none !important;
        }

        &:hover,
        &:focus {
            border-color: $secondary-color;
            background: $primary-color;
            box-shadow: none !important;
        }
    }
}

.nav-links {
    margin: 0 auto;

    &::before,
    &::after {
        display: none;
    }
}

.nav-previous {
    padding-bottom: 1em;
}

.nav-title-icon-wrapper {
    display: none;
}

@media #{$medium-and-up} {
    .nav-links {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        padding: 0;
    }
    .comment-navigation,
    .posts-navigation,
    .post-navigation {

        .nav-previous,
        .nav-next {
            float: left;
            display: flex;
            align-items: stretch;
            width: 48%;
        }
        .nav-next {
            float: right;
            text-align: right;
            margin-top: 0;
        }
    }
    .nav-previous {
        padding-bottom: 0;
    }
}

.post-navigation {
    padding-bottom: 1em;

    .meta-nav {
        display: block;
    }
    .post-title {
        font-size: 1.2em;
        font-weight: 700; // border-bottom: 3px solid $anchor-color-underline;
    }
    a:hover .post-title,
    a:focus .post-title {
        // border-bottom-color: $secondary-color;
    }
}
