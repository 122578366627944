// insert styles for specific number of slides
// # Hero Slider
// -------------
// insert styles for specific number of slides
@mixin slides($hero-slides) {
    width: 100% * ($hero-slides + 1);
    transform: translate3d(0, 0, 0);
    animation-duration: $hero-slides * $hero-slide-duration;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-name: hero-slide-left-#{$hero-slides};

    .hero-slide {
        width: 100% / ($hero-slides + 1);
    }

    @keyframes hero-slide-left-#{$hero-slides} {
        // The amount of time the slider is either in a transition or showing a slide in percent  
        $slider-time: 1 / ($hero-slides + 1) * 100;
        $offset: 1 / ($hero-slides) * 100;
        @for $i from 0 through $hero-slides {
            $percentage: $i * $offset + "%";
            $translate: $i * $slider-time * (-1) + "%";
            #{$percentage} {
                transform: translateX(#{$translate});
            }
            @if $i < $hero-slides {
                $percentage: $i * $offset + $slider-time + "%";
                #{$percentage} {
                    transform: translateX(#{$translate});
                }
            }
        }
    }
}


// apply ken burns effect to the slides
@mixin ken-burns($hero-slides) {
    $total-time: ($hero-slide-duration * $hero-slides);
    $scale-value: (1 + $hero-scale-factor / 100%);

    .hero-slide,
    .hero-image {
        animation-duration: $total-time;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    .hero-image {
        transform: scale($scale-value);
    }

    @for $i from 1 through $hero-slides {
        .hero-slide:nth-child(#{$i}) {
            z-index: ($hero-slides - $i + zindex(overlay));
            animation-name: fade-#{$hero-slides}-#{$i};
            .hero-image {
                animation-name: ken-burns-#{$hero-slides}-#{$i};
            }
        }

        $animation-time-ratio: percentage($hero-slide-duration / $total-time);
        $transition-time-ratio: percentage($hero-transition-duration / $total-time);

        $phase1: max(($animation-time-ratio * ($i - 1) - ($transition-time-ratio / 2)), 0%);
        $phase2: max(($animation-time-ratio * ($i - 1) + ($transition-time-ratio / 2)), 0%);
        $phase3: min(($animation-time-ratio * $i - ($transition-time-ratio / 2)), 100%);
        $phase4: min(($animation-time-ratio * $i + ($transition-time-ratio / 2)), 100%);
        $phase5: (100% - $transition-time-ratio / 2);
        $phase6: (($phase4 - $phase1) * 100% / $phase5);

        $scale-plus: ($hero-scale-factor * (100% - $phase5) / $phase4); // $scale-plus-value: (1 + ($hero-scale-factor + $scale-plus) / 100%);
        $scale-plus-value: (1 - $scale-plus / 100%);

        @keyframes fade-#{$hero-slides}-#{$i} {
            #{$phase1} {
                opacity: 1;
            }
            #{$phase2} {
                opacity: 1;
            }
            #{$phase3} {
                opacity: 1;
            }
            #{$phase4} {
                opacity: 0;
            }

            @if ($i !=$hero-slides) {
                100% {
                    opacity: 0;
                }
            }

            @if ($i==1) {
                #{$phase5} {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
        }

        @keyframes ken-burns-#{$hero-slides}-#{$i} {
            #{$phase1} {
                transform: scale3d(1, 1, 1) translate3d(0px, 0px, 0px); // transform: scale3d($scale-value, $scale-value, $scale-value);
            }
            #{$phase4} {
                // transform: scale3d(1, 1, 1);
                transform: scale3d($scale-value, $scale-value, $scale-value) translate3d(-5vw, 3vh, 0px);
            }

            @if ($i !=$hero-slides) {
                100% {
                    // transform: scale3d($scale-value, $scale-value, $scale-value);
                    transform: scale3d(1, 1, 1) translate3d(0px, 0px, 0px);
                }
            }

            @if ($i==1) {
                #{$phase5} {
                    transform: scale3d($scale-plus-value, $scale-plus-value, $scale-plus-value);
                }
            }
        }
    }
}

@mixin grid-container($child: '.row') {
    margin: 0 auto;
    max-width: 1280px;
    width: 90%;

    @media #{$medium-and-up} {
        width: 85%;
    }
    @media #{$extra-large-and-up} {
        width: 70%;
    }

    & > #{$child} {
        margin-left: (-1 * $gutter-width / 2) !important;
        margin-right: (-1 * $gutter-width / 2) !important;
    }
}

@mixin grid-row {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin grid-section {
    padding-top: 1rem;
    padding-bottom: 1rem;

    &.no-pad {
        padding: 0;
    }
    &.no-pad-bot {
        padding-bottom: 0;
    }
    &.no-pad-top {
        padding-top: 0;
    }
}

@mixin grid-col() {
    float: left;
    box-sizing: border-box;
    padding: 0 $gutter-width / 2;
    min-height: 1px;

    &[class*="push-"],
    &[class*="pull-"] {
        position: relative;
    }

    $i: 1;
    @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.s#{$i} {
            width: $perc;
            @include reset-offset;
        }
        $i: $i+1;
    }

    $i: 1;
    @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        @include grid-classes("s", $i, $perc);
        $i: $i + 1;
    }

    @media #{$medium-and-up} {

        $i: 1;
        @while $i <= $num-cols {
            $perc: unquote((100 / ($num-cols / $i)) + "%");
            &.m#{$i} {
                width: $perc;
                @include reset-offset;
            }
            $i: $i+1
        }

        $i: 1;
        @while $i <= $num-cols {
            $perc: unquote((100 / ($num-cols / $i)) + "%");
            @include grid-classes("m", $i, $perc);
            $i: $i + 1;
        }
    }

    @media #{$large-and-up} {

        $i: 1;
        @while $i <= $num-cols {
            $perc: unquote((100 / ($num-cols / $i)) + "%");
            &.l#{$i} {
                width: $perc;
                @include reset-offset;
            }
            $i: $i+1;
        }

        $i: 1;
        @while $i <= $num-cols {
            $perc: unquote((100 / ($num-cols / $i)) + "%");
            @include grid-classes("l", $i, $perc);
            $i: $i + 1;
        }
    }

    @media #{$extra-large-and-up} {

        $i: 1;
        @while $i <= $num-cols {
            $perc: unquote((100 / ($num-cols / $i)) + "%");
            &.xl#{$i} {
                width: $perc;
                @include reset-offset;
            }
            $i: $i+1;
        }

        $i: 1;
        @while $i <= $num-cols {
            $perc: unquote((100 / ($num-cols / $i)) + "%");
            @include grid-classes("xl", $i, $perc);
            $i: $i + 1;
        }
    }
}

@mixin materialize-icon($content, $font-size: 2rem) {
    display: inline-block;
    content: $content;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: $font-size;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    vertical-align: text-bottom;
    font-feature-settings: 'liga';
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;

    @media #{$medium-and-up} {
        font-size: .75 * $font-size;
    }
}
