.card {
    color: black;
    border-radius: 0;
    overflow: hidden;

    .card-image img {
        border-radius: 0;
    }
}

.card-link {
    display: flex;
    position: absolute;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0; 
    bottom: 0;
    left: 0;
    background: transparent;
    transition: background .5s ease-out;

    .card-name {
        display: block;
        margin-left: .5em;
        margin-right: .5em;
        font-size: 3em;
        opacity: 0;
        text-align: center;

        @media #{$medium-and-up} {
            font-size: 2em;
        }
    }
    
    .card-redirect {
        display: block;
        opacity: 0;
        padding: .7em 1.2em;
        border: 1px solid white;
    }

    &:hover,
    &:focus {
        background: $shadow;

        .card-name {
            animation: fadeInTop .8s cubic-bezier(.45,0,0,1) .3s forwards;
        }

        .card-redirect {
            animation: fadeInBottom .8s cubic-bezier(.45,0,0,1) .3s forwards;
        }
    }
}

.card-action {
    display: block;
    background-color: transparent;
    text-align: center;
    text-transform: uppercase;
    transition: color .3s ease, background-color .3s ease;

    &:hover,
    &:focus {
        color: $primary-color !important;
        background: black;
    }

    @media #{$medium-and-up} {
        display: none;
    }
}
