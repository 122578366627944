.admin-bar{
    .side-nav {
        top: 45px;
        @media screen and (min-width: 783px) {
            top: 32px;
        }
    }
    #wpadminbar {
        @media screen and (max-width: 599px){
            position: fixed;
        }
    }

    .full-width-header {
        @media screen and (min-width: 599px) {
            margin-top: -110px;
        }
        @media screen and (min-width: 783px) {
            margin-top: -96px;
        }
    }

    .front-panel .anchor {
        top: -80px;
    }
}