@keyframes fadeInBottom {
    0% {
        opacity: 0;
        transform: translate3d(0,70px,0);
    }
    100% {
        opacity: 1;
        transform: none;
    }
}

@keyframes fadeInTop {
    0% {
        opacity: 0;
        transform: translate3d(0,-70px,0);
    }
    100% {
        opacity: 1;
        transform: none;
    }
}