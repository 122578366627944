h1,
.single-post .entry-title,
.page .entry-title {
	font-size: 24px;
	font-size: 2.5rem;
}

h2,
.home.blog .entry-title {
	font-size: 20px;
	font-size: 2rem;
}

h3 {
	font-size: 18px;
	font-size: 1.5rem;
}

h4 {
	font-size: 16px;
	font-size: 1.25rem;
}

h5 {
	font-size: 13px;
	font-size: 1rem;
}

h6 {
	font-size: 15px;
	font-size: 0.75rem;
}
