body.single-format-aside {
    .entry-wrapper {
        position: relative;
    }

    .order-form-wrapper {
        @media #{$medium-and-up} {
            position: absolute;
            top: 0;
            right: 0;
            width: 40%;

            div[role="form"] {
                display: block !important;
            }

            .order-form-toggle > span {
                display: none;
            }
        }
    }
}
