.order-form-toggle {
    display: block;
    position: relative;
    padding: 1em 3em;
    width: 100%;
    background: $primary-color;
    color: $secondary-color;
    text-align: center;
    text-transform: uppercase;
    user-select: none;

    &::before,
    &::after {
        @include materialize-icon('arrow_downward');
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    &::before {
        left: .3em;
        @media #{medium-and-up} {
            left: .7em;
        }
    }

    &::after {
        right: .3em;
        @media #{medium-and-up} {
            right: .7em;
        }
    }
}

.order-form-wrapper div[role="form"] {
    span.wpcf7-list-item {
        margin-left: 0;
    }

    ::-webkit-input-placeholder {
        opacity: .5;
    }

    input[type="submit"] {
        font-size: 1.7rem;
        background: invert($primary-color);
        color: $secondary-color;
        
        &:hover,
        &:focus {
            background: $primary-color;
        }
    }
}

.order-form-info-section {
    margin-bottom: 1.5em;

    .info-section-header {
        position: relative;
        margin-bottom: .5em;
        padding: .5em 0;
        font-size: 1.2em;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 300;
    }
}


.js .order-form-wrapper {

    div[role="form"],
    .order-form-toggle.toggled-on > span,
    .order-form-info-section .info-section-content {
        display: none;
    }

    .order-form-toggle {
        cursor: pointer;

        @media #{$medium-and-up} {
            cursor: default;
        }
    }

    .order-form-info-section .info-section-header {  
        cursor: pointer;

        &::after {
            @include materialize-icon('arrow_drop_down');
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        &.toggled-on::after {
            transform: translateY(-50%) scale(-1);
            transform-origin: center center;
            transform-style: preserve-3d;
        }
    }
    
}

.no-js .order-form-toggle > span {
    display: none;
}
