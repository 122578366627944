.site-footer {
    width: 100%;
    margin-top: 0;
    color: white;
    background: $primary-color;
    border-top: 0;

    a {
        box-shadow: none !important;
    }
}

.footer-content-container {
    padding: 1em 0;
}

.footer-content {
    a {
        color: white;
    }
}

.site-info {
    width: 100%;
    float: none;
    text-align: center;
}