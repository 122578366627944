body .entry-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .entry-content {
        margin-right: 0;
        margin-left: 0;

        @media #{$medium-and-up} {
            width: 55%;
        }
    }

    .entry-image {
        margin-bottom: 1em;

        @media #{$medium-and-up} {
            width: 30%;
        }
    }

    figure {
        margin: 0;

        img {
            width: 100%;
        }
    }
}

body.single-format-standard .entry-wrapper {
    @media #{$medium-and-up} {
        flex-direction: row-reverse;
        justify-content: space-between;
    }
}
