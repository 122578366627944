.category.page-one-column:not(.has-sidebar) {
    .page-header,
    #primary {
        // max-width: none;
    }

    .page-header {
        margin-bottom: 0;
    }
}

body.colors-dark .taxonomy-description {
    font-style: italic;
    color: transparentize($color: #ccc, $amount: .4);
}