html,
body {
    height: 100%;
}
.site {
    min-height: 100%;
}

.site,
.site-content-contain {
    display: flex;
    flex-direction: column;
}

.site-content-contain,
.site-content {
    flex: 1 0 auto;
}