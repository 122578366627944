nav .brand-logo {
    font-size: 1.5rem;
    text-transform: uppercase;
    
    @media #{$small-and-down} {
        font-size: 1rem;
    }
}

// Menu Icons: shopping_cart, library_music
.highlight a {
    position: relative;
    background: lighten($primary-color, 35%) !important;
    color: invert($secondary-color) !important;

    &:hover,
    &:focus {
        background: lighten($primary-color, 20%) !important;
    }

    &::before,
    &::after {
        @include materialize-icon('library_music');
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    &::before {
        left: .3rem;
    }

    &::after {
        right: .3rem;
        content: 'shopping_cart';
    }
}

.nav-wrapper .right .highlight a {
    padding: 0 2.2rem;
}
