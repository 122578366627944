.full-width-header {
    display: block;
    position: relative;
    height: 50vh;
    max-height: 100vh;
    background-image: url('assets/images/full-width-header-bg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    @media #{$medium-and-up} {
        height: 100vh;
        margin-top: -64px;
    }

    .media-player {
        display: block;
        position: absolute;
        bottom: 0;
        left: 20%;
        right: 20%;
        opacity: .4;
        transition: opacity .5s;
        z-index: zindex(alwaysontop);

        &:hover {
            opacity: 1;
            transition: opacity .5s;
        }

        @media #{$small-and-down} {
            .mejs-duration-container,
            .mejs-volume-button,
            .mejs-horizontal-volume-slider {
                display: none;
            }
        }
    }
}