.hero-container {
    // height: calc(#{$hero-height} * #{$hero-height-modifier});
    height: $hero-height;
    width: 100%;
    overflow: hidden;
    box-shadow: 1px 4px 15px rgba(0, 0, 0, 0.4);

    @media #{$medium-and-up} {
        height: $hero-height;
    }

    @media #{$large-and-up} {
        margin-top: rem(-58);
    }

    .hero {
        position: relative;
        height: 100%;

        //hide hero to fade it in later via JS
        // opacity: 0;
        // visibility: hidden;

        // &.hero-slider {
        //     font-size: 0;
        //     @for $i from 2 through $hero-slides-max {
        //         &.hero-slider-#{$i} {
        //             @include slides($i);
        //         }
        //     }

        //     .hero-slide {
        //         display: inline-block;
        //         vertical-align: top;
        //     }
        //     &:hover {
        //         animation-play-state: paused;
        //     }
        // }
 
        // &.hero-fading {

        //     .hero-slide {
        //         position: absolute;
        //     }
        //     @for $i from 2 through $hero-slides-max {
        //         &.hero-fading-#{$i} {
        //             @include ken-burns($i);
        //         }

        //     }
        // }
    }
}

.hero-slide {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-shadow: 1px 4px 15px $shadow;

    .hero-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $hero-overlay-color;
    }

    .hero-image {
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100%;
        margin: 0;
    }
}

.hero-content {
    display: none;
    // display: flex;
    position: relative;
    flex-flow: column nowrap;
    justify-content: space-around;
    min-height: 50%;
    max-height: 90%;
    width: 90%;
    margin: auto;
    color: lighten($secondary-color, 10%);
    background: $hero-overlay-color;
}

.hero-title {
    text-align: center;
    font-size: 1.5rem;

    @media #{$medium-and-up} {
        font-size: 3rem;
    }

    @media #{$large-and-up} {
        font-size: 4rem;
    }
}

.hero-text {
    margin-bottom: 1.5em;
    text-align: center;
    font-size: 1rem;
    font-size: $small-font-size;
    line-height: 1.3rem;
    color: lighten($primary-color, 10%);
    @media #{$medium-and-up} {
        font-size: 1.4rem;
        line-height: 2rem;
    }
    @media #{$large-and-up} {
        font-size: 1.8rem;
        line-height: 2.5rem;
    }
}
