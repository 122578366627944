body {
    background-color: rgb(36, 36, 36);
}

body,
button,
input,
select,
textarea {
    font-family: Roboto, sans-serif;
}

.site {
    @media #{$medium-and-up} {
        border: solid $border-width darken($primary-color, 5%);
        border-top: 0;
    }
}

.site-content-contain {
    background: transparent !important;
}

.site-header,
.site-content-contain {
    box-shadow: 0px 0px 20px 4px $shadow;
}

.page .wrap,
.error404 .wrap,
.search .wrap,
.single .wrap {
    padding: 0;
    @include grid-container('.content-area');
    
    .content-area {
        @include grid-row();
        margin-left: auto !important;
        margin-right: auto !important;
        
        .site-main {
            @include grid-col();
            width: 100%;
            padding: 0;
        }
    }
}

.row {
    margin-left: -.75rem;
    margin-right: -.75rem;
}

.entry-title,
.entry-title a,
.site-content,
label {
    color: ghostwhite;
}

ol,
ul {
    margin-left: 1.5em;
}

.page.page-one-column .entry-header,
.twentyseventeen-front-page.page-one-column .entry-header,
.archive.page-one-column:not(.has-sidebar) .page-header {
    margin-bottom: 2em;
}

.single-post:not(.has-sidebar) #primary,
.page.page-one-column:not(.twentyseventeen-front-page) #primary,
.archive.page-one-column:not(.has-sidebar) .page-header,
.archive.page-one-column:not(.has-sidebar) #primary {
    max-width: none;
    margin-left: auto;
    margin-right: auto;
}

body.page:not(.twentyseventeen-front-page) .entry-title,
.page .panel-content .entry-title,
.page-title {
    font-size: 2em;
}

input[type=search] {
    box-sizing: border-box;
}

@media #{$large-and-up} {
    .container {
        width: 85%;
    }
}

@media #{$extra-large-and-up} {
    .container {
        width: 70%;
    }
}
