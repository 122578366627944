.home .site-content {
    padding-top: 0;

    .gallery-title {
        display: none;
    }

    .mousy-gallery {
        justify-content: space-between;

        .gallery-item:last-child() {
            margin-right: 0;
        }
    }
}

.front-panel {
    padding-top: 40px;

    .panel-title {
        color: ghostwhite;
    }

    &.discography .container {
        /* Relative position and z-index > 1 to prevent parallax
        containers further down in the HTML template to cover
        the modals in the discography.
        */
        position: relative;
        z-index: 2;
    }

    label {
        color: ghostwhite;
    }

    .link-more {
        display: none;
    }
}
