.button-link {
    text-align: center;
    margin-top: 2em;

    @media #{$large-and-up} {
        text-align: right;
    }

    a {
        display: inline-block;
        position: relative;
        padding: 1em 1.5em;
        border: 1px solid $primary-color;
        box-shadow: none !important; //override WP style
        font-weight: bold;
        transition: all .15s ease-out;

        span {
            position: relative;
            left: 0;
            transition: all .2s ease-out;
        }

        i::before {
            position: absolute;
            top: 50%;
            right: 30px;
            opacity: 0;
            content: '→';
            font-size: 120%;
            transform: translateY(-60%);
            transition: all .25s ease-out;
        }

        &:hover,
        &:focus {
            background: $primary-color;
            color: white;

            span {
                left: -10px;
            }

            i::before {
                opacity: 1;
                right: 10px;
            }
        }
    }
}
